import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_BOOKINGS} from '../../constants/modules';
import { lazy } from 'react';
import { isOwnUser } from '../../utils/ui';

const PageOpenMatchesFullData = lazy(()=>import('../../pages/OpenMatches/PageOpenMatchesFullData'));
const PageOpenMatchDetail = lazy(()=>import('../../pages/OpenMatches/PageOpenMatchDetail'));
const PageOpenMatchesList = lazy(()=>import('../../pages/OpenMatches/PageOpenMatchesList'));

const RoutesOpenMatches = (
    <>
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ_ALL}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={'/openMatches'} element={<PageOpenMatchesList tab={0}/>}/>
        <Route path={'/openMatches/active'}
               element={<PageOpenMatchesList tab={0}/>}/>
        <Route path={'/openMatches/past'} element={<PageOpenMatchesList tab={1}/>}/>
        
        <Route path={'/openMatches/all/:name'} element={<PageOpenMatchesFullData/>}/>
        
        <Route path={'/openMatches/single/detail'} element={<PageOpenMatchDetail/>}/>
        <Route path={'/openMatches/single/detail/edit'} element={<PageOpenMatchDetail/>}/>
      </Route>
    </>
);
export default RoutesOpenMatches;
