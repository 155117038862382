import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import {NavLink, useLocation} from 'react-router-dom';
import {LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT} from '../../constants/ui';
import {LayoutMainNavbarNavSubMenu} from './LayoutMainNavbarNavSubMenu';
import useIsMobile from '../../hooks/ui/useIsMobile';
import ContainerFullWidthNavbar from '../../components/_Global/Containers/ContainerFullWidthNavbar';
import LayoutMainMobileNavbarSubMenu from './LayoutMainMobileNavbarSubMenu';
import { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

export function LayoutMainNavbarNav({navItems,onClick=()=>{}}) {

  const theme = useTheme();
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isMobile = useIsMobile();
 
  const [navBarItems,setNavbarItems] = useState(navItems);

  const clickOnMenuItem=(currentIndex)=>{

    const copyData = JSON.parse(JSON.stringify(navBarItems));
    const index = copyData.findIndex((x,i)=>i==currentIndex);

    if(index!=-1){
      copyData[index] = {...copyData[index],isOpen:!copyData[index]?.isOpen}
      setNavbarItems(copyData)
    }
  }


  useEffect(()=>{

    setNavbarItems(navItems);
  },[navItems])

  return <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: isMobile?'auto': LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
        display: 'flex',
        alignItems: 'center'
      }}
  >
      <ContainerFullWidthNavbar isMobile={isMobile}>
        {
          isMobile?
          <MenuItemsMobile
          navItems={navBarItems}
          onClick={clickOnMenuItem}
          theme={theme}
          isHome={isHome}
          />:
          <MenuItems
          navItems={navBarItems}
          onClick={onClick}
          theme={theme}
          isHome={isHome}
          />
        }
      </ContainerFullWidthNavbar>
    
  </Box>;
}


const MenuItems=props=>{
  const {isHome,theme,navItems,onClick} = props
  return(
    <Stack
          direction={'row'}
          spacing={3}
          alignItems={'center'}
          component={'ul'}
          sx={{
            padding: 0,
            height: LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
          }}
      >
        {
          navItems.map((listItem, i) => {

            const submenuItems = listItem?.subMenuItems;

            return (
                listItem.canAccess &&
                <Box
                    key={i}
                    component={'li'}
                    sx={{
                      listStyle: 'none',
                      display: 'flex',
                      position: 'relative',
                    }}
                >
                  <Stack
                      component={NavLink}
                      to={listItem.route}
                      onClick={()=>listItem?.onClick? listItem?.onClick() : onClick()}
                      end={true}
                      direction={'row'}
                      alignItems={'center'}
                      color={'text.primary'}
                      className={`${listItem.disabled &&
                      'disabled'} ${listItem.activeOnRoot && isHome &&
                      'active'}`}
                      sx={{
                        textDecoration: 'none',
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '5px 12px',
                        borderRadius: '30px',
                        '&.disabled': {
                          color: `${theme.palette.text.disabled} !important`,
                          pointerEvents: 'none',
                          cursor: 'none',
                        },
                        '&.active, &.active:hover': {
                          backgroundColor: '#D9EAFF',
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.grey[50],
                        },
                      }}
                  >
                    <Typography fontSize={'inherit'} fontWeight={'inherit'}>
                      {listItem.name}
                    </Typography>
                  </Stack>
                  {
                      submenuItems &&
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'text.secondary',
                        cursor: 'pointer',
                      }}>
                        <LayoutMainNavbarNavSubMenu items={submenuItems}/>
                      </Box>

                  }

                </Box>);
          })}
      </Stack>
  )
}

const MenuItemsMobile=(props)=>{

  const {isHome,theme,navItems,onClick} = props
  return(
    <Stack
          direction={'column'}
          spacing={1}
          component={'ul'}
          sx={{
            padding: 0,
            width:'100%',
            m:0
          }}
      >
        {
          navItems.map((listItem, i) => {

            const submenuItems = listItem?.subMenuItems;

            return (
                listItem.canAccess &&
                <Box
                    key={i}
                    component={'li'}
                    sx={{
                      margin:'0px !important',
                      listStyle:'none'
                    }}
                >
                  <Stack
                      component={!submenuItems && NavLink}
                      to={listItem.route}
                      onClick={()=>{

                        if(listItem?.onClick){
                          onClick(i);
                          listItem?.onClick();
                        }else{
                          onClick(i);
                        }
                      }

                      }
                      end={true}
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      color={'text.primary'}
                      className={`${listItem.disabled &&
                      'disabled'} ${listItem.activeOnRoot && isHome &&
                      'active'}`}
                      sx={{
                        width:'100%',
                        textDecoration: 'none',
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '10px 15px',
                        '&.disabled': {
                          color: `${theme.palette.text.disabled} !important`,
                          pointerEvents: 'none',
                          cursor: 'none',
                        },
                        '&.active, &.active:hover': {
                          backgroundColor: '#D9EAFF',
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.grey[50],
                        },
                      }}
                  >
                    <Typography fontSize={'inherit'} fontWeight={'inherit'}>
                      {listItem.name} 
                    </Typography>
                    {
                      submenuItems && (
                        !!listItem?.isOpen?
                      <KeyboardArrowDown/>:
                      <KeyboardArrowRight/>)
                    }
                  </Stack>
                  <Divider/>
                  {
                      submenuItems && listItem?.isOpen &&
                      <LayoutMainMobileNavbarSubMenu items={submenuItems}/>
                  }

                </Box>);
          })}
      </Stack>
  )
}