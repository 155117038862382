import * as Sentry from '@sentry/react';

function ProviderSentry({children}) {
  const env = process.env.REACT_APP_ENV;
  const isLocal = env === 'local';

  if (!isLocal) {
    Sentry.init({
      environment: env,
      dsn: 'https://61c8c307c7816d77eeac17e834eb95f4@o4508205600145408.ingest.de.sentry.io/4508206326612048',
      // integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0,
    });
  }
  return <Sentry.ErrorBoundary>
    {children}
  </Sentry.ErrorBoundary>;
}

export default ProviderSentry;
